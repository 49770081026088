import { createTheme, darkThemeOptions } from '@sitoo/mui-theme'
import { ThemeName } from '../store/config'

export const lightTheme = createTheme({
  typography: { fontFamily: 'depot-new-web' },
})

export const darkTheme = createTheme({
  typography: { fontFamily: 'depot-new-web' },
  palette: { colors: {}, ...darkThemeOptions.palette },
})

export type Theme = typeof lightTheme

export const getThemeByName = (themeName: ThemeName) => {
  return themeName === ThemeName.DARK ? darkTheme : lightTheme
}
